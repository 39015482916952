import { getUser } from "../utils/auth"
import TestingRecord from "../models/TestingRecord"

import { getCollectionByName } from "../utils/firebase"

const testingHistoryCollection = getCollectionByName("testingHistory")

export function loadTestingRecords() {
  const allTopics = getUser().allTopics

  return testingHistoryCollection
    .where("uid", "==", getUser().uid)
    .get()
    .then(querySnapshot => {
      const testingRecords = []

      querySnapshot.forEach(doc => {
        testingRecords.push({
          ...doc.data(),
          id: doc.id,
        })
      })

      return groupTestingRecordsByTopic(testingRecords, allTopics)
    })
}

function groupTestingRecordsByTopic(testingRecords, allTopics) {
  var testedTopics = []
  let testedTopicsIds = []

  testingRecords.forEach(record => {
    if (testedTopicsIds.indexOf(record.topicId) === -1) {
      testedTopicsIds.push(record.topicId)
    }
  })

  testedTopics = allTopics
    ? allTopics.filter(item => testedTopicsIds.includes(item.id))
    : null

  return testedTopics
    ? testedTopics.map(testedTopic => {
        const testingRecordsByTopic = testingRecords
          .filter(record => record.topicId === testedTopic.id)
          .sort((a, b) => {
            return a.timestamp - b.timestamp
          })
          .slice(-3)
        return {
          topicName: testedTopic.name,
          testingRecords: testingRecordsByTopic,
        }
      })
    : null
}

export function addTestingRecord(selected, data, time) {
  let timestamp = new Date().getTime()
  let correctAnswers = getCorrectAnswers(data, selected)
  let total = getTotalQuestion(data)

  const testingRecord = new TestingRecord()
  testingRecord.timestamp = timestamp
  testingRecord.correctAnswers = correctAnswers
  testingRecord.topicId = data.topicId
  testingRecord.total = total
  testingRecord.time = formatTime(time)

  testingRecord.uid = getUser().uid

  return testingHistoryCollection.add(Object.assign({}, testingRecord))
}

export const formatTime = time => {
  if (time < 60) {
    return time < 10 ? `0${time}s` : `${time}s`
  } else {
    return Math.floor(time / 60) + "m" + (time % 60) + "s"
  }
}

export function getCorrectAnswers(data, selected) {
  let score = 0
  for (const item of data.content) {
    for (const item1 of item.questions) {
      if (item1.correct === selected.get(item1.question + item.mondai)) {
        score = score + 1
      }
    }
  }
  return score
}

export const getTotalQuestion = data => {
  let total = 0
  for (const item of data.content) {
    total = total + item.questions.length
  }
  return total
}

export const timestampToDate = timestamp => {
  let date = new Date(timestamp)
  return (
    date.getHours() +
    ":" +
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    " - " +
    date.getDate() +
    "/" +
    (date.getMonth() + 1) +
    "/" +
    date.getFullYear()
  )
}
